<template>
  <component
    :is="linkDisabled ? 'span' : 'router-link'"
    :to="{
      name: 'meeting',
      params: { id: event.e.id, calendarId: event.cid || '' },
      query: { private: showPrivate ? null : undefined },
    }"
    class="w-full"
  >
    <m-card
      class="event-panel"
      :class="{
        'event-highlight': event.e.id == highlight,
        'cursor-pointer': !linkDisabled,
      }"
    >
      <!-- content -->
      <div class="lg:flex justify-between">
        <div
          class="flex lg:justify-start items-start lg:items-center mt-1 mb-1"
          style="min-width: 0"
        >
          <div
            class="text-grey1 lg:ml-3 font-normal text-xl lg:truncate max-w-sm"
            :class="status == 'pastEvent' ? 'opacity-50' : ''"
            :title="event.e.summary"
            data-recording-sensitive
          >
            {{ event.e.summary ? event.e.summary : '(No title)' }}
          </div>
          <!-- any icons, decide here if show or not -->
          <div class="flex flex-grow lg:flex-grow-0"></div>
          <div class="flex ml-1 mt-1 lg:mt-0 mr-0 lg:mr-4" v-if="meetricInfo">
            <MCINotes
              v-if="
                meetricInfo.public.has_notes || meetricInfo.private.has_notes
              "
            ></MCINotes>
            <MCIActions
              v-if="
                meetricInfo.public.actions.total > 0 ||
                meetricInfo.private.actions.total > 0
              "
              v-bind:actionsTotal="
                meetricInfo.public.actions.total +
                meetricInfo.private.actions.total
              "
              v-bind:actionsCompleted="
                meetricInfo.public.actions.complete +
                meetricInfo.private.actions.complete
              "
            ></MCIActions>
          </div>
          <div class="flex ml-4" v-else>
            <m-placeholder class="ml-1 h-5 w-16 rounded"></m-placeholder>
          </div>
        </div>
        <div class="lg:hidden my-2 font-medium">
          <div
            class="my-auto event-time text-grey3"
            :class="event.e.id == highlight ? 'event-highlight' : ''"
            data-recording-sensitive
          >
            {{ eventTime }}
          </div>
        </div>
        <div class="hidden lg:flex mt-1 mr-2">
          <meeting-guests
            :guests="guests"
            :eventStatus="status"
            v-bind:showOnlineStatus="false"
            v-bind:showDetails="true"
          ></meeting-guests>
        </div>
        <!-- Time of the event on MOBILE -->
      </div>
    </m-card>
  </component>
</template>

<script>
import MCard from '@/components/UI/MCard';
import MeetingGuests from '@/components/MeetingGuest/MeetingGuests';
import MCIActions from '@/components/UI/MeetingCardIcons/Actions';
import MCINotes from '@/components/UI/MeetingCardIcons/Notes';
import MPlaceholder from '@/components/UI/MPlaceholder';

export default {
  name: 'EventPanel',
  components: {
    MCard,
    MeetingGuests,
    MCIActions,
    MCINotes,
    MPlaceholder,
  },
  props: {
    linkDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    event: {
      type: Object,
      required: true,
    },
    meetricInfo: {
      required: false,
      default: () => {},
    },
    status: {
      type: String,
      required: false,
    },
    highlight: {
      type: String,
      required: false,
    },
    eventTime: {
      type: String,
      required: false,
    },
    guests: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    showPrivate() {
      // if event has only private notes/actions, we want link to open private view

      if (
        !this.meetricInfo?.public.has_notes &&
        this.meetricInfo?.public.actions.total === 0 &&
        (this.meetricInfo?.private.has_notes ||
          this.meetricInfo?.private.actions.total > 0)
      )
        return true;

      return false;
    },
  },
};
</script>
<style scoped>
/* Border around next meeting in meeting list */
.event-panel.event-highlight {
  @apply border-2 border-base2 border-opacity-50 border-l-0 lg:border-l-2 border-r-0 lg:border-r-2;
}
.event-panel:not(.event-highlight) {
  @apply border-2 border-transparent border-l-0 lg:border-l-2 border-r-0 lg:border-r-2;
}
</style>
