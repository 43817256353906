<template>
  <div class="w-full flex" :class="eventStatus" :data-eventid="event.e.id">
    <div class="hidden text-grey1 lg:flex lg:mx-5 font-medium w-40">
      <div
        class="event-time my-auto text-grey3"
        :class="{
          'event-highlight': event.e.id == highlight,
          'no-current-highlight': !highlightCurrentTime,
        }"
        data-recording-sensitive
      >
        {{ formatEventTime }}
      </div>
    </div>
    <div class="flex flex-grow my-auto">
      <!-- Panel with the event info -->
      <event-panel
        class="w-full"
        :event="event"
        :status="eventStatus"
        :meetric-info="meetricInfo"
        :highlight="highlight"
        :eventTime="formatEventTime"
        :guests="guests"
        v-bind:linkDisabled="linkDisabled"
      >
      </event-panel>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import EventPanel from './EventPanel';
import { isPastEvent, isCurrentEvent } from '@/components/Utils';

export default {
  name: 'MeetingsListGroup',
  components: {
    EventPanel,
  },
  props: {
    linkDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    highlightCurrentTime: {
      type: Boolean,
      required: false,
      default: true,
    },
    event: {
      type: Object,
      required: true,
    },
    meetricInfo: {
      required: false,
      default: () => {},
    },
    highlight: {
      required: false,
      type: String,
      default: '',
    },
    guests: {
      required: false,
      type: Array,
    },
  },
  computed: {
    eventStatus() {
      if (isPastEvent(this.event.e)) {
        return 'pastEvent';
      }
      if (isCurrentEvent(this.event.e)) {
        return 'currentEvent';
      }
      return 'futureEvent';
    },
    formatEventTime() {
      if (this.event.e.start.dateTime) {
        const startTime = moment(this.event.e.start.dateTime);
        const endTime = moment(this.event.e.end.dateTime);
        return startTime.format('hh:mma') + ' - ' + endTime.format('hh:mma');
      }
      return '12:00am - 12:00am';
    },
  },
};
</script>

<style scoped>
.currentEvent .event-time:not(.no-current-highlight),
.event-highlight.event-time {
  @apply text-base2;
}
</style>
